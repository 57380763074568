<template>
  <el-dialog
    :title="form.id ? '修改法律类型' : '新增法律类型'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <el-form-item
        label="类型名称"
        prop="typeName"
        :rules="[ { required: true, message: '类型名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.typeName"
        />
      </el-form-item>
      <el-form-item
        label="类型编号"
        prop="typeCode"
        :rules="[ { required: true, message: '编号不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.typeCode"
        />
      </el-form-item>
      <el-form-item label="序号">
        <el-input
          v-model="form.sortNo"
          type="number"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateLawType, GetLawTypeById } from '@/api/knowledgeBase/laws'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        typeCode: '',
        typeName: '',
        sortNo: 0,
        id: 0,
      },
      groupOptions: [],
      dimensions: [],
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.id) {
        this.getLawTypeById()
      }
    },
    dialogClose() {
      this.form = {
        typeCode: '',
        typeName: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    getLawTypeById() {
      GetLawTypeById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
        }
      })
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          this.form.sortNo = Number(this.form.sortNo)
          saveOrUpdateLawType(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
