<template>
  <el-card>
    <el-row
      class="filter-wrap"
      :gutter="10"
    >
      <el-col :span="4">
        <div class="flex-start">
          <el-input
            v-model="listQuery.SearchStr"
            placeholder="请输入内容"
            clearable
          />
        </div>
      </el-col>
      <el-col :span="3">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </el-col>
      <el-col
        :span="17"
        class="text-right"
      >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="类型名称"
        prop="typeName"
      />
      <el-table-column
        label="类型编号"
        prop="typeCode"
      />
      <el-table-column
        label="序号"
        prop="sortNo"
      />
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="saveDialogShow(2,row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLawType(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <LawTypeSave
      ref="lawTypeSave"
      @handleFilter="handleFilter"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import { knowledgeLawTypePage, DeleteLawTypeByIds } from '@/api/knowledgeBase/laws'
import LawTypeSave from '@/views/knowledge/lawType/LawTypeSave.vue'

export default {
  components: {
    pagination,
    LawTypeSave,
  },

  data() {
    return {
      dialogVisible: false,
      loading: false,
      loading1: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    saveDialogShow(saveType, row) {
      this.$refs.lawTypeSave.dialogVisible = true
      if (saveType === 2) {
        this.$refs.lawTypeSave.form.id = Number(row.id)
      }
    },
    delLawType(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLawTypeByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success('删除成功')
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      knowledgeLawTypePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
